<!--应收账款汇总表-->
<template>
    <div class="ContractAccountsReceivableCollected">
        <reportTable
            code="SalePriceDetails"
            :query="query"
            :percentage="percentage"
            @generateComplete="generateComplete"
            custom-export
            @exportTable="exportTable"
        >
            <template #customTable>
                <u-table
                    ref="virtualTable"
                    :data="tableList"
                    :height="height"
                    :row-height="40"
                    row
                    use-virtual
                    border
                    v-loading="loading"
                >
                    <u-table-column
                        :label="tableTitle"
                    >
                        <u-table-column
                            v-for="(item,index) in tableHeader"
                            :key="'col'+index"
                            :label="item.label"
                            :prop="item.prop"
                            :width="item.width"
                        >
                        </u-table-column>
                    </u-table-column>
                </u-table>
            </template>
        </reportTable>
    </div>
</template>

<script>
import reportTable from '@/components/reportTable/reportTable.vue';
import { export_json_to_excel } from '@/assets/js/exportExcel/index';
import utils from '@/utils/util';
import qs from 'qs';
export default {
    components: { reportTable },
    data() {
        const tableHeader = [
            {
                label: '合同编号',
                prop: 'contract_number',
                width: '160',
            },
            {
                label: '客户名称（账面）',
                prop: 'client_name',
                width: '200',
            },
            {
                label: '工程名称',
                prop: 'engineering_name',
                width: '200',
            },
            {
                label: '合同类型',
                prop: 'contract_type_name',
                width: '100',
            },
            {
                label: '签订日期',
                prop: 'signing_date',
                width: '100',
            },
            {
                label: '供货起止时间',
                prop: 'supply_time',
                width: '250',
            },
            {
                label: '供货站点',
                prop: 'station_name',
                width: '100',
            },
            {
                label: '签约方量',
                prop: 'projected_capacity',
                width: '100',
            },
            {
                label: '已供方量',
                prop: 'delivery_total_quantity',
                width: '100',
            },
            {
                label: '业务终止时间',
                prop: 'data_date',
                width: '120',
            },
            {
                label: '回款',
                prop: 'current_amounts_amount',
                width: '100',
            },
            {
                label: '总回款',
                prop: 'total_collection_amount',
                width: '100',
            },
            {
                label: '总货款',
                prop: 'trade_receivables',
                width: '100',
            },
            {
                label: '实际欠款',
                prop: 'balance_payment_amount',
                width: '100',
            },
            {
                label: '按合同应回款',
                prop: 'aggregate_receivables',
                width: '120',
            },
            {
                label: '应收账款余额',
                prop: 'accounts_receivable_balance',
                width: '120',
            },
            {
                label: '合同约定付款方式',
                prop: 'settlement_mode_and_payment_clause',
                width: '300',
            },
            {
                label: '类型',
                prop: 'type_name',
                width: '100',
            },
            {
                label: '有无合同',
                prop: 'is_have_contract',
                width: '100',
            },
            {
                label: '有无对账',
                prop: 'is_have_statement',
                width: '100',
            },
            {
                label: '销售经理',
                prop: 'salesman_name',
                width: '100',
            },
        ];
        return {
            loading: false,
            tableHeader: tableHeader,
            query: {},
            tableTitle: '应收账款汇总表',
            tableList: [],
            totalData: [[]],
            // 分批加载 大小
            limit: 10,
            // 进度条
            percentage: 100,
            queryArr: null,
            height: 0,
        };
    },
    created() {},
    mounted() {
        let resize = null;
        window.addEventListener('resize', () => {
            resize = this.setBodyHeight(resize);
        });
        this.setBodyHeight(resize);
    },
    methods: {
        setBodyHeight(resize) {
            clearInterval(resize);
            const timer = setTimeout(() => {
                this.height = document.querySelector('.table-box').clientHeight;
                if (this.$refs.virtualTable) {
                    this.$refs.virtualTable.doLayout();
                }
            }, 50);
            return timer;
        },
        // 报表生成完成
        async generateComplete(params) {
            try {
                this.queryArr = null;
                this.loading = true;
                this.tableList = [];
                this.percentage = 0;
                const startTimes = new Date(params.startTime).getTime();
                const endTimes = new Date(params.endTime).getTime();
                const splitTimes = this.limit * 24 * 60 * 60 * 1000;
                const arr = [];
                for (let times = startTimes; times <= endTimes; times += splitTimes) {
                    const start = utils.timeFormat(new Date(times), 'yyyy-MM-dd');
                    let endms = times + splitTimes;
                    if (endms >= endTimes) {
                        endms = endTimes;
                    }
                    const end = utils.timeFormat(new Date(endms), 'yyyy-MM-dd');
                    if (arr.length > 0 && start === end) {
                        break;
                    }
                    arr.push([start, end]);
                }
                this.queryArr = arr;
                for (const i in arr) {
                    if (this.queryArr !== arr) {
                        return;
                    }
                    const pam = Object.assign( {}, params);
                    pam.startTime = arr[i][0];
                    pam.endTime = arr[i][1];
                    const listUrl = `/interfaceApi/report/accounts_receivable_summary/data_list?${qs.stringify(pam)}`;
                    const list = await this.$axios.get(listUrl);
                    list.forEach(item => {
                        item.supply_time = (item.first_supply_time || '') + '-' + (item.latest_supply_time || '');
                    });
                    this.percentage = parseInt((Number(i) + 1) / arr.length * 100, 0);
                    this.tableList.push(...list);
                    if (Number(i) === 0) {
                        this.loading = false;
                    }
                }
                if (this.tableList.length > 0) {
                    this.getTotalData(params);
                }
                this.percentage = 100;
            } catch (e) {
                this.$message.error(e.ErrorCode ? e.ErrorCode.Message : e.message);
            } finally {
                this.percentage = 100;
                this.loading = false;
            }
        },
        // 获取合计数据
        async getTotalData(params) {
            const url = `/interfaceApi/report/accounts_receivable_summary/data_total?${qs.stringify(params)}`;
            const res = await this.$axios.get(url);
            const data = {};
            this.tableHeader.forEach(item => {
                data[item.prop] = '';
            });
            data.contract_number = '合计';
            data.projected_capacity = res.projected_capacity_subtotal;
            data.delivery_total_quantity = res.delivery_total_quantity_subtotal;
            data.current_amounts_amount = res.current_amounts_amount_subtotal;
            data.total_collection_amount = res.total_collection_amount_subtotal;
            data.trade_receivables = res.trade_receivables_subtotal;
            data.balance_payment_amount = res.balance_payment_amount_subtotal;
            data.aggregate_receivables = res.aggregate_receivables_subtotal;
            data.accounts_receivable_balance = res.accounts_receivable_balance_subtotal;
            this.tableList.push(data);
        },
        // summaryMethod() {
        //     return this.totalData;
        // },
        exportTable() {
            const data = [];
            const merges = [
                { s: { r: 0, c: 0 }, e: { r: 0, c: this.tableHeader.length - 1 } },
            ];
            const header1 = new Array(this.tableHeader.length).fill('');
            header1[0] = this.tableTitle;
            const header2 = this.tableHeader.map(item => item.label);
            const header = [
                header1,
                header2,
            ];
            this.tableList.forEach(row => {
                const arr = [];
                this.tableHeader.forEach(item => {
                    arr.push(row[item.prop]);
                });
                data.push(arr);
            });
            export_json_to_excel({
                multiHeader: header,
                merges: merges,
                data,
                filename: this.tableTitle,
            });
        },
    },
    destroyed() {
        this.queryArr = null;
    },
};
</script>
<style lang="stylus" scoped>

</style>


